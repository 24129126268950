<template lang="pug">
.grid
	.title Настройки
	template(v-if="personalData" )
		b {{ personalData.surname }} {{ personalData.name }} {{ personalData.middleName }}
		p(v-if="personalData.phone") Телефон: {{ personalData.phone }}
	p Пользователь: {{ userName }}
	p ID:{{ ' ' }}
		router-link(:to="`/workers/${id}`") {{ id }}
	span(v-if="getRoles") Роли: {{ getRoles }}
	BaseGroup(title="Сменить пароль" :grid="3")
		BaseInput(v-model="passwords.new" placeholder="Новый пароль" type="password")
		BaseInput(v-model="passwords.repeat" placeholder="Повторите новый пароль" type="password")
		BaseButton(@click="changePassword") Сменить
	BaseGroup(title="Выход" :grid="3")
		BaseButton(@click="logout") Выйти
</template>

<script>
import { onMounted, reactive, ref } from 'vue'

import { useApi } from '../modules/api'
import { useAlert } from '../modules/composition/useAlert'
import { clearToken } from '@/utils/useToken'

import { mergeDeep } from '../utils/mergeDeep'

import BaseGroup from '../components/ui/BaseGroup'
import BaseButton from '../components/ui/BaseButton'
import BaseInput from '../components/ui/BaseInput'
import TheList from '../components/TheList'
import BaseCard from '../components/ui/BaseCard'

export default {
	name: 'Settings',
	components: { BaseCard, TheList, BaseInput, BaseButton, BaseGroup },
	setup () {
		const { errorAlert, infoAlert } = useAlert()

		const { id, login: userName, roles } = JSON.parse(localStorage.account)

		const getRoles = Object.keys(roles).join(', ')

		const personalData = ref(null)

		onMounted(async () => {
			const { get, data } = useApi(`worker/${id}`)

			await get()

			personalData.value = data.value
		})

		const passwords = reactive({
			new: null,
			repeat: null
		})

		const changePassword = async () => {
			if (passwords.new.length < 4) {
				errorAlert('Пароль должен содержать не менее 4 символов')

				return
			}

			if (passwords.new !== passwords.repeat) {
				errorAlert('Пароли не совпадают')

				return
			}

			const { get, data, error: e1 } = useApi('/worker')
			const { post: create, error: e2 } = useApi('/worker/create')

			await get(`/${id}`)

			await create(mergeDeep(data.value, { password: passwords.new }))

			if (e1.value || e2.value) {
				errorAlert('Не удалось изменить пароль. Попробуйте перезагрузить страницу и попробовать ещё раз.')
			} else {
				infoAlert('Пароль успешно изменён')
			}

			passwords.new = null
			passwords.repeat = null
		}

		const logout = async () => {
			clearToken()
			window.location.reload()
		}

		return {
			id,
			userName,
			personalData,
			getRoles,

			passwords,
			changePassword,

			logout
		}
	}
}
</script>
