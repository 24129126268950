<template lang="pug">
.list
	.list__element.pointer(
		v-for="(element, key) in map"
		:key="key"
		:class="{ hover }"
		@click="$emit('onClick', key)"
	)
		p
			b(v-if="element.left" v-html="markedSubstring(element.left)")
			span(v-if="element.leftSub" v-html="markedSubstring(element.leftSub)")
		p
			b(v-if="element.right" v-html="markedSubstring(element.right)")
			span(v-if="element.rightSub" v-html="markedSubstring(element.rightSub)")
</template>

<script>
export default {
	name: 'TheList',
	props: {
		map: {
			type: Object,
			default: () => {}
		},
		search: {
			type: [String, null],
			default: null
		},
		hover: {
			type: Boolean,
			default: true
		}
	},
	setup (props) {
		const markedSubstring = (string) => {
			if (props.search) {
				const start = string.indexOf(props.search)

				if (start >= 0) {
					const finish = start + props.search.length

					return `${string.slice(0, start)}<span class="marked-text">${string.slice(start, finish)}</span>${string.slice(finish)}`
				}
			}
			return string
		}

		return {
			markedSubstring
		}
	}
}
</script>

<style lang="scss" scoped>
.list {
	&__element {
		transition: background-color 0.3s ease;
		padding: 4px;
		display: grid;

		p {
			b {
				display: block;
				margin-bottom: 2px;
			}
		}

		@include until-mobile {
			p + p {
				margin-top: 4px;
			}
		}

		@include for-tablet {
			display: flex;
			justify-content: space-between;
			align-items: center;

			p {
				&:last-child {
					text-align: right;
				}
			}

			&.hover {
				&:hover {
					background-color: rgba($bgMain, 0.1);
				}
			}
		}

		& + & {
			border-top: 1px solid $borderPrimary;
		}
	}
}
</style>
